import { useState, useEffect, useContext } from 'react';
import { Leaderboard, NotificationContext } from '@mdc/ui';
import { CDNService, SERVICE_ERRORS, QueryParams, logService, InternalNavigation } from '@mdc/services';
import { NOTIFICATION_TYPES } from '@mdc/constants';
import { useTranslation } from 'react-i18next';

const TopVoters = () => {

    const [availableDates, setAvailableDates] = useState([]);
    const [leaderboard, setLeaderboard] = useState([]);
    const [latestReport, setLatestReport] = useState(null);
    const { notify } = useContext(NotificationContext);
    const color = 'green';
    const folder = 'reports/leaderboard-voters/';
    const { t, ready } = useTranslation();
    const { date } = QueryParams();
    const title = t('Top Voters');
    const description = t('Leaderboard generated from user votes on all files');

    const onCDNerror = (errData) => {
        logService.log(errData); // eslint-disable-line no-console
        notify({
            message: t('The leaderboard data you are trying to view is not accessible. Please make sure you have the correct link and try again'),
            type: NOTIFICATION_TYPES.CRITICAL
        });
        updateDate('latest');
    };

    const onCDNinvalid = () => {
        notify({
            message: t('There was an issue loading the report. Please try again later'),
            type: NOTIFICATION_TYPES.CRITICAL
        });
        updateDate('latest');
    };

    const handleCDNnavigate = (path) => {
        InternalNavigation.navigation(path);
    };

    useEffect(() => {
        (async () => {
            try {
                CDNService.on(SERVICE_ERRORS.REPORT_ERROR, onCDNerror);
                CDNService.on(SERVICE_ERRORS.INVALID_REPORT, onCDNinvalid);
                const oldReports = await CDNService.getCDNReport('older-reports.json', folder, handleCDNnavigate);
                const latest = await CDNService.getCDNReport('latest.json', folder, handleCDNnavigate);

                if (Array.isArray(oldReports) && latest && latest.leaderboard && Array.isArray(latest.leaderboard)) {
                    setAvailableDates(oldReports);
                    setLatestReport(latest.leaderboard);

                    if (date?.toLowerCase() !== 'latest') {
                        updateDate(date);
                    } else {
                        setLeaderboard(latest.leaderboard);
                    }

                }
            } catch (error) {
                logService.error(error);  // eslint-disable-line no-console
            }
        })();
    }, []);

    useEffect(() => {
        if (date?.toLowerCase() === 'latest') {
            if (latestReport) {
                setLeaderboard(latestReport);
            } else {
                updateDate('latest');
            }
        }
    }, [date]);

    const updateDate = (fileName) => {
        if (!fileName) {
            return;
        }

        window && window.history.replaceState('', '', `?date=${fileName}`);

        if (fileName.toLowerCase() === 'latest') {
            if (latestReport) {
                setLeaderboard(latestReport);
                return;
            }
        }

        (async () => {
            try {
                const updatedLatest = await CDNService.getCDNReport(`${fileName}.json`, folder, handleCDNnavigate);
                if (updatedLatest && Array.isArray(updatedLatest.leaderboard)) {
                    setLeaderboard(updatedLatest.leaderboard);
                }
            } catch (error) {
                logService.error(error);  // eslint-disable-line no-console
            }
        })();
    };

    const data = leaderboard.map((item) => {
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('votes')) {
            item.score = item.votes;
            delete item.votes;
        }
        return item;
    });

    if (!ready) {
        return null;
    }

    return (
        <Leaderboard
            availableDates={availableDates}
            leaderboard={data}
            updateDate={updateDate}
            title={title} description={description}
            color={color}>
        </Leaderboard>
    );
};


export default TopVoters;
