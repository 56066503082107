import { useContext, useMemo } from 'react';
import Layout from '../../components/layouts/freshest/FreshestLayout';
import SideBarPageLayout from '../../components/layouts/sidebar-page/SidebarPageLayout';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TopVoters from '../../components/leaderboard/top-voters/TopVoters';
import TopMalwareSubmitters from '../../components/leaderboard/top-malware-submitters/TopMalwareSubmitters';
import { UserContext } from '@mdc/ui';
import useAuthRedirect from '../../components/useAuthRedirect';

const Leaderboard = (props) => {
    const { t, ready } = useTranslation();
    const userContext = useContext(UserContext);
    const { pageContext } = props;
    const currentPage = pageContext.currentPage;
    useAuthRedirect();


    const menuItems = useMemo(() => {
        const items = [
            {
                'label': t('Malware Submitters'),
                'icon': 'icon-star',
                'to': '/leaderboard'
            },
            {
                'label': t('Voters'),
                'icon': 'icon-vote',
                'to': '/leaderboard/top-voters'
            },
            {
                'label': t('Change nickname'),
                'icon': 'icon-sign',
                'to': '/account'
            }
        ];

        return userContext.state === userContext.STATES.LOGGED_IN ? items : items.filter((item) => item.to !== '/account');
    }, [userContext, t]);

    const PageFragment = useMemo(() => {
        if (currentPage === 'top-voters') {
            return (<TopVoters />);
        } else {
            return (<TopMalwareSubmitters />);
        }
    }, [currentPage]);

    if (!ready) {
        return null;
    }

    return (
        <Layout pageTitle='leaderboard' pageDescription={'Leaderboard generated from submissions made by free users'}>
            <SideBarPageLayout menuItems={menuItems} pageName={currentPage} pageTitle={'Leaderboards'}>
                {PageFragment}
            </SideBarPageLayout>
        </Layout>
    );
};

Leaderboard.propTypes = {
    currentPage: PropTypes.string,
    pageContext: PropTypes.object.isRequired
};

export default Leaderboard;
